.found-a-professional-layout {
    overflow: visible;
    .discover-online-service-text {
        color: var(--white-color);
        font-size: 1.2rem;
    }
    .found-a-professional-search-layout {
        background: linear-gradient(90deg, var(--main-color-3) 100%, var(--white-color) 0%);

        position: relative;
        z-index: -2;
        .found-your-client-btn {
            background-color: var(--white-color);
        
            &:hover {
                background-color: var(--secondary-color-1);
                color: var(--white-color);
            }
        }
    }
    .found-a-professionnal-input {
        height: 3.5rem;
        background-color: var(--main-color-4);
    }
    .found-a-professionnal-input::placeholder {
        color: var(--main-color-1);
    }
    .found-your-client-btn {
        height: 3.5rem;
    }
    .found-a-professional-first-box {
        height: 13rem;
    }
    .found-a-professional-last-box {
        display: flex;
        align-items: flex-end;
        transform: translateY(0rem);
        z-index: -1;
    }
    .professional-image-mobile {
        transform: scale(1.3);
        max-height: 18rem;
    }
    .professional-image-desktop {
        transform: scale(1.3) translateX(6rem) translateY(-2rem);
        position: absoulute;
    }
    .patate-7 {
        position: absolute;
        left: 5rem;
        top: 5rem;
        height: 8rem;
    }
}

@media (min-width: $breakpoint-md) {
    .found-a-professional-layout {
        .found-a-professional-search-layout {
            background: linear-gradient(90deg, var(--main-color-3) 90%, var(--white-color) 0%);
        }
        .found-a-professional-first-box {
            height: auto;
        }
        .professional-image-mobile {
            transform: scale(1.3);
            max-height: 18rem;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .found-a-professional-layout {
        .professional-image-desktop {
            transform: scale(1.4) translateX(6rem) translateY(-2rem);
            // transform: scale(1.3) translateX(6rem) translateY(-6rem);
            position: absoulute;
        }
        .found-a-professional-search-layout {
            background: linear-gradient(90deg, var(--main-color-3) 80%, var(--white-color) 20%);
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .found-a-professional-layout {
        .professional-image-desktop {
            transform: scale(1.4) translateX(8rem) translateY(-6rem);
            position: absoulute;
        }
    }
}
