.annuaire-des-antennes-et-agences-layout {
    .headband {
        background-color: var(--main-color-5);
        height: 3.2rem;
    }
    .tab-list  {
        li {
            color: var(--secondary-color-1);
            height: 100%;
            padding: 0 1rem;
            font-weight: 600;
            cursor: pointer;
            
            .border-hover {
                width: 0%;
                height: 3px;
                background-color: var(--main-color-2);
                transition: .3s;
            }
            &:hover .border-hover {
                width: 100%;
            }
        }
    }
    .antenna-card {
        .antenna-card-header {
            height: 12rem;
            width: 100%;
            background-image: url('https://picsum.photos/200/300');
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        .antenna-card-pills {
            transform: translateY(1rem);
            background-color: var(--main-color-3);
            padding: .2rem .6rem;
            font-size: .8rem;
        }
        .antenna-card-content {
            h3 {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.5rem;
            }
        }

    }
}