.our-latest-press-release-layout {
    .patate-8 {
        position: absolute;
        height: 20rem;
        top: 5rem;
        right: -10%;
        z-index: -1;
    }
    .our-latest-press-release-background {
        z-index: 1;
        height: 25rem;
        background: url("/assets/images/guest/our-latest-press-release.jpg");
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }
    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    p {
        color: var(--white-color);
    }
    .swiper-pagination {
        span {
            background-color: var(--white-color);
        }
    }
    .right-sticked-column {
        position: relative;
        padding-top: 22rem;
    }
    .js-our-latest-press-release-slider {
        position: absolute;
        margin: auto;
        top: 0;
        width: 30rem;
        display: flex;
        justify-content: center;
        height: auto;
        min-height: 25rem;
        padding: 2rem 0;
    }
    .our-latest-press-release-card-layout {
        margin-top: -2rem;
        transform: translateY(2rem);
    }
    .our-latest-press-release-border {
        border-bottom: 4px solid var(--main-color-2);
    }
    .our-latest-press-release-card-layout {
        position: relative;
        z-index: 1000;
        transform: translateY(4rem);
    }
    .our-latest-press-release-card {
        div {
            background-color: var(--main-color-2);
            max-width: 20rem;
        }
        p {
            font-size: 0.9rem;
            font-weight: 300;
        }
    }
}
@media (min-width: $breakpoint-md) {
    .our-latest-press-release-layout {
        .patate-8 {
            right: 10%;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .our-latest-press-release-layout {
        margin-top: 10rem !important;
        .patate-8 {
            top: 5rem;
            right: -5%;
        }
        .right-sticked-column {
            position: relative;
            padding-top: 15rem;
        }
        .our-latest-press-release-background {
            height: 30rem;
            background-position: 50% 25%;
        }
        .right-sticked-column {
            position: relative;
        }
        .js-our-latest-press-release-slider {
            position: absolute;
            left: 0;
            top: -5rem;
            width: 30rem;
            padding: 2rem 0;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .our-latest-press-release-layout {
        .patate-8 {
            top: 5rem;
            left: 15rem;
        }
    }
}
