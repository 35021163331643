.home-header-layout {
    position: relative;
    .patate-9 {
        z-index: 1000;
        bottom: -2rem;
        right: 20%;
        position: absolute;
        transform: rotate(35deg);
        height: 6rem;
    }
    .home-header-topbar {
        margin-bottom: 5rem;
        img {
            height: 4rem;
        }
        p {
            color: var(--white-color);
            font-weight: 500;
            font-size: .8rem;
        }
    }
    .gsap-home-patate {
        width: 100px;
        height: 100px !important;
        position: absolute;
        bottom: -3rem;
        right: 5rem;
        z-index: 6789;
        line-height: 100px;
        font-size: 50px;
        text-align: center;
        transform: scale(1);
    }
    .home-header-card {
        cursor: pointer;
        .rounded-shape-title {
            color: var(--white-color);
            font-weight: 500;
        }
        .border-hover-bottom {
            height: 1rem;
            width: 0%;
            border-bottom: 4px solid transparent;
            transition: .3s;
        }
        .cps-glyph {
            top: 20px;
            font-size: 3rem;
        }
    }
    .home-header-card-1 {
        .cps-glyph {
            color: var(--theme-color-1-2);
        }
        &:hover {
            .border-hover-bottom {
                width: 100%;
                border-color: var(--theme-color-1-2);
            }
            .cps-glyph {
                color: var(--theme-color-1-2);
            }
        }
    }
    .home-header-card-2 {
        .cps-glyph {
            color: var(--theme-color-2-2);
        }
        &:hover {
            .border-hover-bottom {
                width: 100%;
                border-color: var(--theme-color-2-2);
            }
            .cps-glyph {
                color: var(--theme-color-2-2);
            }
        }
    }
    .home-header-card-3 {
        .cps-glyph {
            color: var(--theme-color-3-2);
        }
        &:hover {
            .border-hover-bottom {
                width: 100%;
                border-color: var(--theme-color-3-2);
            }
            .cps-glyph {
                color: var(--theme-color-3-2);
            }
        }
    }
    .home-header-card-4 {
        .cps-glyph {
            color: var(--theme-color-4-2);
        }
        &:hover {
            .border-hover-bottom {
                width: 100%;
                border-color: var(--theme-color-4-2);
            }
            .cps-glyph {
                color: var(--theme-color-4-2);
            }
        }
    }

    .home-header-wallpaper {
        padding-bottom: 3rem;
        position: relative;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;

        .header-text {
            color: var(--white-color);
        }
        .header-image {
            object-fit: cover;
            height: 100%;
            transform: scale(1.4);
            object-position: 0 top;
            position: absolute;
            top: -320px;
        }
        .home-header-wallpaper-curved-separator-svg {
            position: absolute;
            display: block !important;
            bottom: -4px;
            left: 0;
            right: 0;
            z-index: 1000;
            transform: scale(1.1);
        }
        article {
            position: relative;
            background-size: cover;
            background-position: bottom;
        }
        h2 {
            color: var(--white-color);
            font-weight: 700;
        }
        .home-header-wallpaper-overlay {
            position: absolute;
            background-color: var(--main-color-opacity);
            height: 100%;
            width: 100%;
            display: block;
        }
    }
}

/* Media queries */
@media (min-width: $breakpoint-md) {
    .home-header-layout {
    }
}
@media (min-width: $breakpoint-lg) {
    .home-header-layout {
        .patate-9 {
            z-index: 1000;
            bottom: -2rem;
            right: 20%;
            position: absolute;
            transform: rotate(35deg);
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .home-header-layout {
        .home-header-wallpaper {
           min-height: 45rem;
        }
        .patate-9 {
            z-index: 1000;
            bottom: -2rem;
            right: 20%;
            position: absolute;
            transform: rotate(35deg);
            height: 6rem;
        }
        .header-image {
            object-fit: cover;
            height: 100%;
            width: 80%;
            transform: scale(1);
            object-position: 0 top;
            position: absolute;
            top: -320px;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .home-header-layout {
        .home-header-wallpaper {
        }
        .home-header-wallpaper {
            height: 100vh;
            // min-height: 55rem;
         }

    }
}
