.phone-service-layout {
    background-color: var(--main-color-2);

    .phone-service-title {
        font-weight: 600;
        width: 100%;
    }
    .phone-service-subtitle {
        font-size: 0.8rem;
        width: 100%;
        font-weight: 400;
    }
    p {
        color: var(--white-color);
    }
}

ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    text-decoration: none;
    color: var(--white-color);
    text-decoration: none;
}
ul > li .social-link {
    color: var(--white-color);
    text-decoration: none !important;
}
ul > li .social-link:hover {
    color: var(--main-color-1);
}
