.topnav-layout {
    .topnav-title {
        color: var(--secondary-color-1);
        font-weight: 700;

    }
    // .breadcrumb-item:after {
    //     content: '>';
    //     padding-left: .5rem;
    // }
    // .breadcrumb-item:last-child:after {
    //     content: '';
    // }
}