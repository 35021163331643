* {
    font-family: "Poppins", sans-serif;
}
h2 {
    letter-spacing: 0.1rem;
    font-weight: 700 !important;
}
.cps-close-to-you-card-overlay {
    h4 {
        font-weight: 600 !important;
    }
}
.overflow-x-inherit {
    overflow-y: inherit;
}
.gl-arrow-link {
    text-decoration: none;
    color: var(--dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    display: inline-block;
    border-bottom: 2px solid var(--main-color-2);
    transition: 0s;

    .icon-CPS_11 {
        font-size: 1rem;
        color: var(--dark-color);
    }

    .gl-arrow-link-round {
        display: none;
        height: 2.2rem;
        width: 2.2rem;
        font-size: 1rem !important;
        border: 2px solid var(--dark-color);
    }

    &:hover {
        color: var(--main-color-2);
    }
    &:hover .gl-arrow-link-round {
        background-color: var(--main-color-2);
        border-color: var(--main-color-2);
    }
    &:hover .icon-CPS_11 {
        color: var(--white-color);
    }
}

.gl-arrow-link-reverse {
    color: var(--white-color);
    border-color: var(--main-color-3);

    .gl-arrow-link-round {
        border-color: var(--white-color);
    }
    .icon-CPS_11 {
        color: var(--white-color);
    }

    &:hover {
        color: var(--main-color-3);
        .gl-arrow-link-round {
            border-color: var(--main-color-3);
        }
        .icon-CPS_11 {
            color: var(--main-color-3);
        }
    }
}

@media (min-width: 992px) {
    .gl-arrow-link-box {
        padding-left: 0;
    }
    .gl-arrow-link {
        display: flex;
        justify-content: start;
        border-bottom: none;
        svg {
            display: block;
        }
        div {
            display: block;
        }
        .gl-arrow {
            font-size: 1rem;
            color: var(--white-color);
        }
    }
    .gl-arrow-link-variant {
        border-bottom: 2px solid var(--main-color-2);
    }
}
