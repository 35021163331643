.frequent-steps-layout {
    max-width: 80rem;
    position: relative;

    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    span {
        font-size: 4em;
        color: var(--main-color-2);
    }
    .frequent-steps-card {
        height: 15rem;
        cursor: pointer;

        .cps-glyph {
            color: var(--main-color-2);
        }
        .frequent-steps-rounded-shape {
            z-index: -1;
            fill: var(--main-color-2);
            position: absolute;
            height: 100%;
            width: 100%;
        }
        .frequent-steps-rounded-shape > path {
            fill: var(--secondary-color-2);
        }
        h3 {
            font-size: 1.6rem;
            font-weight: 600;
        }
        p {
            font-size: 1.2rem;
            color: var(--dark-color);
            font-weight: 300;
        }
        &:hover .cps-glyph {
            color: var(--white-color);
            cursor: pointer;
        }
        &:hover path {
            fill: var(--main-color-3);
        }
        &:hover h3 {
            color: var(--white-color);
        }
        &:hover p {
            color: var(--white-color);
        }
    }
}

/* Media queries */
@media (min-width: $breakpoint-md) {
    .frequent-steps-layout {
        .frequent-steps-card {
            height: 12rem;
            width: 12rem;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .frequent-steps-layout {
        .border-hover-bottom {
            align-self: center;
            height: 2px;
            width: 0%;
            background-color: var(--main-color-2);
            background-color: transparent;
            transition: .3s ease-out;
        }
        .frequent-steps-card h3 {
            font-size: 1.4rem;
            font-weight: 600;
        }
        .frequent-steps-card p {
            font-size: 1rem;
            color: var(--dark-color);
            font-weight: 300;
        }
        .frequent-steps-card .frequent-steps-rounded-shape {
            height: 60%;
            width: 60%;
        }
        .gl-arrow-link-box {
            margin-top: 7rem;
            margin-bottom: 1rem;
        }

        /* Hover effects */
        .frequent-steps-card {
            &:hover h3 {
                color: var(--main-color-1);
            }
            &:hover p {
                color: var(--main-color-1);
            }
            &:hover .border-hover-bottom {
                background-color: var(--main-color-2);
                width: 60%;
            }
        }
    }
}
