.cps-close-to-you-layout {
    .patate-7 {
        z-index: -1;
        position: absolute;
        top: 10rem;
        right: -3rem;
        height: 10rem;
    }
    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    p {
        font-size: 0.9rem;
        font-weight: 300;
    }
    .primary-paragraph {
        color: var(--dark-color);
    }
    .secondary-paragraph {
        color: var(--main-color-2);
    }
    .cps-close-to-you-layout-card-img-top {
        height: 10rem;
    }

    /* card all */
    .cps-close-to-you-card {
        height: 20rem;
        position: relative;
        cursor: pointer;
        background-color: var(--white-color);
        overflow: hidden;

        h4 > small {
            font-size: 1rem;
            font-weight: 300 !important;
        }
        .cps-close-to-you-card-overlay {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
        }
        .background-img {
            position: absolute;
            top: 0;
            background-image: url("/assets/images/guest/cps-close-to-you-1.jpeg");
            background-size: cover;
        }
        .cps-close-to-you-card-link-box {
            height: 2.5rem;
            width: 2.5rem;
            background-color: rgb(25, 239, 188);
            display: flex;
            margin: auto;
            margin-top: 1rem;
        }
        .cps-close-to-you-card-schedule {
            font-weight: 500;
        }
        .nav-link {
            height: 0;
            transition: .3s;
            transform: translateY(10rem);
        }
        .cps-close-to-you-card-schedule-nav-link  {
            height: 0;
            opacity: 0 !important;
        }

        /* Hover */
        &:hover {
            .nav-link {
                height: auto;
                transition: .3s;
                transform: translateY(0rem);
            }
        }
        &:hover .card-title {
            color: var(--white-color) !important;
            height: 10rem;

            padding-top: 2rem;
        }
        &:hover small {
            color: var(--white-color) !important;
        }
        &:hover .cps-close-to-you-card-link-box {
            color: var(--white-color);
            display: flex !important;
        }
        &:hover .cps-close-to-you-card-link-text {
            font-weight: 600;
            display: block !important;
        }
    }

    /* card 1 */
    .cps-close-to-you-card-1 {
        .card-title {
            color: var(--theme-color-3-1);
        }
        .cps-close-to-you-card-schedule {
            color: var(--theme-color-3-2);
        }
        .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(135, 75, 0, 0) 0%,
                rgba(135, 75, 0, 0) 100%
            );
        }
        .cps-close-to-you-card-link-box {
            background-color: var(--theme-color-3-2);
            color: var(--white-color);
            display: none !important;
        }
        .cps-close-to-you-card-link-text {
            color: var(--theme-color-3-2);
            font-weight: 600;
            display: none !important;
        }

        /* Hover effects */
        &:hover .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(30, 58, 142, 1) 0%,
                rgba(30, 58, 142, 0.55) 100%
            );
            border-bottom: 4px solid var(--theme-color-3-2);
        }
    }

    /* card 2 */
    .cps-close-to-you-card-2 {
        .card-title {
            color: var(--theme-color-4-1);
        }
        .cps-close-to-you-card-schedule {
            color: var(--theme-color-4-2);
        }
        .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(135, 75, 0, 0) 0%,
                rgba(135, 75, 0, 0) 100%
            );
        }
        .cps-close-to-you-card-link-box {
            background-color: var(--theme-color-4-2);
            color: var(--white-color);
            display: none !important;
        }
        .cps-close-to-you-card-link-text {
            color: var(--theme-color-4-2);
            font-weight: 600;
            display: none !important;
        }

        /* Hover effects */
        &:hover .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(1, 88, 56, 1) 0%,
                rgba(1, 88, 56, 0.55) 100%
            );
            border-bottom: 4px solid var(--theme-color-4-2);
        }
    }

    /* card 3 */
    .cps-close-to-you-card-3 {
        .card-title {
            color: var(--theme-color-2-1);
        }
        .cps-close-to-you-card-schedule {
            color: var(--theme-color-2-2);
        }
        .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(135, 75, 0, 0) 0%,
                rgba(135, 75, 0, 0) 100%
            );
        }
        .cps-close-to-you-card-link-box {
            background-color: var(--theme-color-2-2);
            color: var(--white-color);
            display: none !important;
        }
        .cps-close-to-you-card-link-text {
            color: var(--theme-color-2-2);
            font-weight: 600;
            display: none !important;
        }

        &:hover .cps-close-to-you-card-overlay {
            background: linear-gradient(
                360deg,
                rgba(135, 75, 0, 1) 0%,
                rgba(135, 75, 0, 0.55) 100%
            );
            border-bottom: 4px solid var(--theme-color-2-2);
        }
    }
}

@media (min-width: 992px) {
    .cps-close-to-you-layout {
        .patate-7 {
            z-index: -1;
            top: 25rem;
            left: 2rem;
            height: 10rem;
        }
        .swiper-wrapper {
            .cps-close-to-you-card-1 {
                margin-top: 8rem;
            }

            .cps-close-to-you-card-3 {
                margin-top: 4rem;
            }
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .cps-close-to-you-layout {
        .patate-7 {
            left: 8%;
            height: 10rem;
        }
    }
}
