.toolbar-aside-layout {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    height: 3rem;

    .toolbar-container {
        background-color: var(--secondary-color-1);
        width: 100%;
    }
    .col {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid var(--main-color-2);
        height: 100%;
        color: var(--main-color-3);
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
            color: var(--white-color);
        }
    }
}
@media (min-width: 768px) {
    .toolbar-aside-layout {
        width: 3rem;
        height: 15rem;

        right: 0;
        top: 10rem;
        border-right: none;
        .toolbar-container {
            border-radius: 20px 0px 0px 20px;
            flex-direction: column;

            .col {
                border-bottom: 1px solid var(--main-color-1);
                height: 3rem;
            }
            .col:last-of-type {
                border-bottom: 1px solid transparent;
            }
        }
    }
}
