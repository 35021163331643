.discover-online-services-layout {
    max-width: 80rem;
    position: relative;

    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    span {
        font-size: 4em;
        color: var(--main-color-2);
    }
    .discover-online-services-card {
        height: auto;
        cursor: pointer;
        border: 1px solid var(--main-color-3);

        .cps-glyph {
            color: var(--main-color-2);
        }
        .discover-online-services-rounded-shape {
            z-index: -1;
            fill: var(--main-color-2);
            position: absolute;
            height: 100%;
            width: 100%;
        }
        .discover-online-services-rounded-shape > path {
            fill: var(--secondary-color-2);
        }
        h3 {
            font-size: 1.6rem;
            font-weight: 600;
        }
        p {
            font-size: 1.2rem;
            color: var(--dark-color);
            font-weight: 300;
        }
        &:hover {
            border-bottom: none;
        }
        &:hover .variant-1 {
            border-bottom: 1px solid var(--theme-color-1-2);
            .cps-glyph {
                color: var(--theme-color-1-2);
                cursor: pointer;
            }
        }
        &:hover .variant-2 {
            border-bottom: 1px solid var(--theme-color-2-2);
            .cps-glyph {
                color: var(--theme-color-2-2);
                cursor: pointer;
            }
        }
        &:hover .variant-3 {
            border-bottom: 1px solid var(--theme-color-5-2);
            .cps-glyph {
                color: var(--theme-color-5-2);
                cursor: pointer;
            }
        }
        &:hover .variant-4 {
            border-bottom: 1px solid var(--theme-color-4-2);
            .cps-glyph {
                color: var(--theme-color-4-2);
                cursor: pointer;
            }
        }
        &:hover .variant-5 {
            border-bottom: 1px solid var(--theme-color-5-1);
            .cps-glyph {
                color: var(--theme-color-5-1);
                cursor: pointer;
            }
        }
        &:hover path {
            fill: var(--main-color-3);
        }
    }
}

/* Media queries */
@media (min-width: $breakpoint-md) {
    .discover-online-services-layout {
        .discover-online-services-card {
            height: auto;
            min-height: 16rem;
            width: 12rem;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .discover-online-services-layout {
        .border-hover-bottom {
            align-self: center;
            height: 2px;
            width: 80%;
            background-color: var(--main-color-2);
            background-color: transparent;
        }
        .discover-online-services-card h3 {
            font-size: 1.4rem;
            font-weight: 600;
        }
        .discover-online-services-card p {
            font-size: 1rem;
            color: var(--dark-color);
            font-weight: 300;
        }
        .discover-online-services-card .discover-online-services-rounded-shape {
            height: 60%;
            width: 60%;
        }
        .gl-arrow-link-box {
            margin-top: 7rem;
            margin-bottom: 1rem;
        }

        /* Hover effects */
        .discover-online-services-card {
            &:hover h3 {
                color: var(--main-color-1);
            }
            &:hover p {
                color: var(--main-color-1);
            }
            &:hover .border-hover-bottom {
                background-color: var(--main-color-2);
            }
        }
    }
}
