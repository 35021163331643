.found-your-client-roadmap-layout {
    .found-your-client-roadmap-card {
        color: var(--white-color);
        background-color: var(--main-color-1);
        h2 {
            max-width: 30rem;
        }
        p {
            max-width: 30rem;
        }
    }

    .found-your-client-btn {
        background-color: var(--white-color);

        &:hover {
            background-color: var(--secondary-color-1);
            color: var(--white-color);
        }
    }
    .found-your-client-roadmap-layout-divider {
        height: 1.2rem;
        background: url("/assets/images/guest/patterns/CPS_pattern_bottom_guest.svg");
        background-size: contain;
        background-position: bottom;
        transform: translateY(-0.1rem);
    }
    .found-your-client-roadmap-patate-5 {
        z-index: 1;
        transform: rotate(65deg) scale(2.3);
    }
    .found-your-client-roadmap-patate-6 {
        transform: scale(1.4) rotate(40deg) translateY(3rem);
        position: absolute;
        z-index: -10;
        top: -2rem;
        left: 9rem;
    }
    .cps-glyph {
        z-index: 2;
        font-size: 5.5rem;
        color: var(--main-color-1);
    }
}
@media (min-width: $breakpoint-md) {
    .found-your-client-roadmap-layout {

        .found-your-client-roadmap-patate-6-desktop {
            z-index: 10;
            position: absolute;
            left: -1rem;
            top: 9rem;
            transform: scale(2.5) rotate(60deg);
        }
        .found-your-client-roadmap-patate-5-desktop {
            z-index: -10;
            position: absolute;
            left: -3rem;
            transform: scale(1.3) rotate(50deg);
        }
        .cps-glyph-desktop {
            z-index: 10;
            position: absolute;
            top: 11rem;
            left: 0rem;
            color: var(--main-color-1);
            transform: scale(5.3);
        }
    }
}
