.see-frequents-questions-layout-divider {
    height: 1.2rem;
    background: url("/assets/images/guest/patterns/CPS_pattern_top_guest.svg");
    background-size: contain;
    background-position: bottom;
    transform: translateY(0.1rem);
}
.patate-3 {
    position: absolute;
    z-index: 10000;
    top: -6rem;
    right: -5.5rem;
    height: 10rem;
    transform: rotate(-20deg);
}
.patate-4 {
    position: absolute;
    z-index: 10000;
    top: -2rem;
    height: 4rem;
    right: 2rem;
    transform: rotate(-18deg);
}
.see-frequents-questions-layout {
    position: relative;
    background-color: var(--main-color-2);
    h2, p {
        color: var(--white-color);
    }

    .card-visible {
        display: block;
        height: 100%;
        transition: .3s ease-in-out;
    }
    .card-invisible {
        height: 100%;
        background-color: var(--main-color-3);
        transition: .3s ease-in-out;
    }
    .see-frequents-questions-card {
        height: 100% !important;
        background-color: var(--white-color);
        cursor: pointer;
        .cps-glyph-top {
            font-size: 4rem;
            color: var(--main-color-4);
        }
        .cps-glyph-bottom {
            font-size: 2rem;
            color: var(--main-color-4);
        }
    }
    .see-frequents-questions-card-title {
        color: var(--main-color-1);
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.9rem;
    }
    .see-frequents-questions-card-text {
        color: var(--main-color-1);
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .see-frequents-questions-card:hover {
        .card-visible {
            transform: translateY(-100%);
        }
        .card-invisible {
            transform: translateY(-100%);
        }
    }
}

/* Slider */
.see-frequents-questions-slider {
    // padding-left: 4rem;
    // padding-right: 4rem;
    height: 100%;
    font-size: 1rem;
}
.swiper-button-next, .swiper-button-prev {
    // top: 0;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    background-color: var(--main-color-2);
    border-radius: 50%;
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 1rem;
    color: var(--white-color);
}
.swiper-button-next, .swiper-button-prev {
    transform: translateY(4rem);
}
// .swiper-button-prev {
//     transform: translateX(-1rem);
// }
.swiper-wrapper {
    // padding: 0 8rem;
    max-width: 45rem;
    // overflow: visible;
}

/* card all */
.see-frequents-questions-layout {
    .frequent-steps-card-overlay:hover p {
        display: block;
    }
    .frequent-steps-card-overlay:hover a {
        display: flex;
    }
    .frequent-steps-card .icon-CPS_11 {
        color: var(--white-color);
    }
    /* card 1 */
    .frequent-steps-card-overlay:hover {
        background: linear-gradient(
            360deg,
            rgba(0, 97, 129, 1) 0%,
            rgba(0, 97, 129, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-1-2);
    }
    /* card 2 */
    .frequent-steps-card-overlay-2:hover {
        background: linear-gradient(
            360deg,
            rgba(135, 75, 0, 1) 0%,
            rgba(135, 75, 0, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-2-2);
    }
    /* card 3 */
    .frequent-steps-card-overlay-3:hover {
        background: linear-gradient(
            360deg,
            rgba(30, 58, 142, 1) 0%,
            rgba(30, 58, 142, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-3-2);
    }
    /* card 4 */
    .frequent-steps-card-overlay-4:hover {
        background: linear-gradient(
            360deg,
            rgba(1, 88, 56, 1) 0%,
            rgba(1, 88, 56, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-4-2);
    }
}

@media (min-width: $breakpoint-md) {
    .see-frequents-questions-layout {
        .frequent-steps-card {
            max-width: 20rem !important;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .patate-3 {
        top: -10rem;
        right: -8.5rem;
        height: 15rem;
        transform: rotate(-10deg);
    }
    .patate-4 {
        top: -6rem;
        height: 6rem;
        right: 2rem;
        transform: rotate(-18deg);
    }
    .carousel-layout {
        .main-slider {
            height: 30rem;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .patate-3 {
        top: -10rem;
        right: -8.5rem;
        height: 20rem;
        transform: rotate(-10deg);
    }
    .patate-4 {
        top: -6rem;
        height: 8rem;
        right: 6rem;
        transform: rotate(-18deg);
    }
    .see-frequents-questions-layout {
        padding-top: 6rem !important;
        .frequent-steps-card {
            max-width: none !important;
        }
    }
}
