// Globals
@import "./icons.scss";
@import "./global.scss";
@import "./variables.scss";

// Templates
@import "./templates/index.scss";

.color-1 {
    height: 1rem;
    width: 1rem;
    background-color: var(--main-color-1);
}
.color-2 {
    height: 1rem;
    width: 1rem;
    background-color: var(--main-color-2);
}
.color-3 {
    height: 1rem;
    width: 1rem;
    background-color: var(--main-color-3);
}
.color-4 {
    height: 1rem;
    width: 1rem;
    background-color: var(--main-color-4);
}
