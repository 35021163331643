.carousel-layout {
    position: relative;

    .patate-1 {
        position: absolute;
        z-index: 10000;
        bottom: -1rem;
        left: 2.5rem;
        height: 5rem;
    }
    .patate-2 {
        position: absolute;
        z-index: 10000;
        bottom: -4.5rem;
        height: 10rem;
        left: -4rem;
    }
    .main-slider {
        height: 20rem;
        position: relative;

        .main-slider-curved-separator-svg {
            position: absolute;
            display: block !important;
            bottom: -4px;
            left: 0;
            right: 0;
            z-index: 1000;
            transform: scale(1.1);
        }
        article {
            position: relative;
            background-size: cover;
            background-position: bottom;
        }
        .main-slider-btn {
            background-color: var(--white-color);

            &:hover {
                background-color: var(--secondary-color-1);
                color: var(--white-color);
            }
        }
        h2 {
            color: var(--white-color);
            font-weight: 700;
        }
        .swiper-wrapper {
        }
        .swiper-slide-overlay {
            background-color: var(--main-color-opacity);
            height: 100%;
            width: 100%;
            display: block;
        }
        .swiper-pagination-bullet {
            background: transparent;
            width: 20px;
            height: 25px;
            text-align: center;
            line-height: 20px;
            opacity: 1;
            border-radius: 0;
            font-size: 1rem;
            margin: 0 0.8rem !important;
            color: var(--white-color);
        }
        .swiper-pagination-bullet-active {
            color: #fff;
            border-bottom: 2px solid var(--white-color);
        }
    }
}

/* Media queries */
@media (min-width: $breakpoint-md) {
    .carousel-layout {
        .main-slider {
            height: 25rem;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .carousel-layout {
        .patate-1 {
            bottom: -1rem;
            left: 2.5rem;
            height: 6rem;
        }
        .patate-2 {
            bottom: -7.5rem;
            height: 15rem;
            left: -8rem;
        }
        .main-slider {
            height: 40rem;
        }
        h2 {
            font-size: 2.8rem;
        }
        .swiper-pagination-bullet {
            font-size: 1.2rem !important;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .carousel-layout {
        .main-slider {
            height: 40rem !important;
        }
        .patate-1 {
            bottom: -1rem;
            left: 4rem;
            height: 8rem;
        }
        .patate-2 {
            bottom: -8.5rem;
            height: 18rem;
            left: -8rem;
        }
        .main-slider-btn {
            margin-top: 3.5rem !important;
        }
    }
}
