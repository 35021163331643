.navbar-layout {
    background: var(--white-color);
    .e-services-access {
        background-color: var(--main-color-3);
        color: var(--dark-color-2);
    }
    .navbar-nav a {
        font-size: 0.8rem;
        color: var(--dark-color) !important;
        border-right: 1px solid var(--light-color);

        &:hover {
            color: var(--main-color-2) !important;
        }
    }
    .navbar-nav > a:last-child {
        border-right: none;
    }
    .slash {
        font-size: 0.5rem !important;
    }
}
