.life-event-layout {
    position: relative;
    .patate-5 {
        position: absolute;
        z-index: 10000;
        top: 0rem;
        right: 10%;
        height: 7rem;
    }
    .patate-6 {
        position: absolute;
        z-index: 10000;
        bottom: -2rem;
        left: -3.5rem;
        height: 10rem;
    }
    .life-event-people-mobile-image {
        margin-top: 4rem;
        margin-bottom: 1rem;
        height: auto;
        width: 100%;
        transform: scale(1.3);
    }
    .life-event-layout-divider {
        height: 1.2rem;
        background: url("/assets/images/guest/patterns/CPS_pattern_bottom_guest.svg");
        background-size: contain;
        background-position: bottom;
        transform: translateY(-0.1rem);
    }
    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    h3 {
        color: var(--main-color-2);
        font-size: 1.1rem;
        font-weight: 600;
    }
    p {
        color: var(--dark-color);
    }
    .life-event-images {
        height: 25rem;
    }

    /* Slider styles */
    .life-event-slider-layout {
        padding: 2rem 0rem;
        background-color: var(--main-color-1);

        .life-event-slider {
            max-width: 60rem;
            .swiper-button-next,
            .swiper-button-prev {
                color: var(--white-color);
            }
        }
    }

    /* Card */
    .life-event-card-layout {
        background-color: var(--main-color-1);
    }
    .life-event-card {
        .life-event-card-title {
            font-size: 1.4rem;
        }
        .life-event-card-link-round {
            position: absolute;
            background-color: var(--theme-color-2-2);
            height: 3rem;
            width: 3rem;
            bottom: -1.1rem;
            left: calc(50% - 1.5rem);
            justify-content: center;
            // display: none;
            transform: scale(0);
        }
        & > div {
            height: 13rem;
            width: 13rem;
            border: 2px solid var(--white-color);
            color: var(--white-color);
            cursor: pointer;
        }
        & > div > p {
            color: var(--white-color);
        }
        & > div > p > strong {
            display: block;
            font-weight: 600 !important;
        }
        & > div > svg {
            bottom: -1.3rem;
            left: calc(50% - 1rem);
            height: 2.5rem;
            width: 2.5rem;
        }

        /* Hover effects */
        & > div:hover {
            background-color: var(--white-color);
        }
        & > div:hover p {
            color: var(--theme-color-2-2) !important;
        }
        & > div:hover .life-event-card-link-round {
            color: var(--theme-color-2-2) !important;
            display: flex;
            transition: .2s ease-out;
            transform: scale(1);
            transition-delay: .1s;
        }
        & > div:hover span {
            color: var(--white-color) !important;
        }
    }
    .life-event-card-1 {
        & > div:hover p {
            color: var(--theme-color-1-2) !important;
        }
        & > div:hover .life-event-card-link-round {
            color: var(--theme-color-1-2) !important;
            display: flex;
        }
        & .life-event-card-link-round {
            background-color: var(--theme-color-1-2);
        }
    }
    .life-event-card-2 {
        & > div:hover p {
            color: var(--theme-color-2-2) !important;
        }
        & > div:hover .life-event-card-link-round {
            color: var(--theme-color-2-2) !important;
            display: flex;
        }
        & .life-event-card-link-round {
            background-color: var(--theme-color-2-2);
        }
    }
    .life-event-card-3 {
        & > div:hover p {
            color: var(--theme-color-4-2) !important;
        }
        & > div:hover .life-event-card-link-round {
            color: var(--theme-color-4-2) !important;
            display: flex;
        }
        & .life-event-card-link-round {
            background-color: var(--theme-color-4-2);
        }
    }
}

@media (min-width: 650px) {
    .life-event-layout {
        .patate-5 {
            right: 15%;
        }
    }
}
@media (min-width: $breakpoint-md) {
    .life-event-layout {
        .patate-5 {
            height: 8rem;
            right: 22%;
        }
        .life-event-people-mobile-image {
            margin-top: 4rem;
            margin-bottom: 1rem;
            height: auto;
            width: 100%;
            transform: scale(1.1);
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .life-event-layout {
        margin-top: 2rem;
        .patate-5 {
            height: 12rem;
            right: 8%;
            top: -4rem;
        }
        .life-event-people-desktop-image {
            height: 30rem;
            width: auto;
            transform: scale(1.3) translateX(3rem) translateY(1rem);
        }
        .patate-6 {
            bottom: -4rem;
            height: 11rem;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .life-event-layout {
        padding: 2rem 0;
        .patate-5 {
            height: 12rem;
            right: 14%;
            top: -4rem;
        }

        .patate-6 {
            bottom: -6rem;
            left: -3.5rem;
            height: 15rem;
        }
    }
}
@media (min-width: 1350px) {
    .life-event-layout {
        .patate-5 {
            height: 12rem;
            right: 18%;
            top: -4rem;
        }
    }
}
@media (min-width: 1450px) {
    .life-event-layout {
        .patate-5 {
            height: 12rem;
            right: 20%;
            top: -4rem;
        }
    }
}
@media (min-width: 1550px) {
    .life-event-layout {
        .patate-5 {
            height: 12rem;
            right: 22%;
            top: -4rem;
        }
    }
}
@media (min-width: 1650px) {
    .life-event-layout {
        .patate-5 {
            height: 12rem;
            right: 25%;
            top: -4rem;
        }
    }
}
@media (min-width: 1850px) {
    .life-event-layout {
        .patate-5 {
            height: 12rem;
            right: 28%;
            top: -4rem;
        }
    }
}