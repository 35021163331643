@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?t155yu');
    src:  url('fonts/icomoon.eot?t155yu#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?t155yu') format('truetype'),
      url('fonts/icomoon.woff?t155yu') format('woff'),
      url('fonts/icomoon.svg?t155yu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-CPS_1:before {
    content: "\e900";
  }
  .icon-CPS_2:before {
    content: "\e901";
  }
  .icon-CPS_3:before {
    content: "\e902";
  }
  .icon-CPS_4:before {
    content: "\e903";
  }
  .icon-CPS_5:before {
    content: "\e904";
  }
  .icon-CPS_6:before {
    content: "\e905";
  }
  .icon-CPS_7:before {
    content: "\e906";
  }
  .icon-CPS_8:before {
    content: "\e907";
  }
  .icon-CPS_9:before {
    content: "\e908";
  }
  .icon-CPS_10:before {
    content: "\e909";
  }
  .icon-CPS_11:before {
    content: "\e90a";
  }
  .icon-CPS_12:before {
    content: "\e90b";
  }
  .icon-CPS_13:before {
    content: "\e90c";
  }
  .icon-CPS_14:before {
    content: "\e90d";
  }
  .icon-CPS_15:before {
    content: "\e90e";
  }
  .icon-CPS_16:before {
    content: "\e90f";
  }
  .icon-CPS_17:before {
    content: "\e910";
  }
  .icon-CPS_18:before {
    content: "\e911";
  }
  .icon-CPS_19:before {
    content: "\e912";
  }
  .icon-CPS_20:before {
    content: "\e913";
  }
  .icon-CPS_21:before {
    content: "\e914";
  }
  .icon-CPS_22:before {
    content: "\e915";
  }
  .icon-CPS_23:before {
    content: "\e916";
  }
  .icon-CPS_24:before {
    content: "\e917";
  }
  .icon-CPS_25:before {
    content: "\e918";
  }
  .icon-CPS_26:before {
    content: "\e919";
  }
  .icon-CPS_27:before {
    content: "\e91a";
  }
  .icon-CPS_28:before {
    content: "\e91b";
  }
  .icon-CPS_29:before {
    content: "\e91c";
  }
  .icon-CPS_30-68:before {
    content: "\e91d";
  }
  .icon-CPS_30:before {
    content: "\e91e";
  }
  .icon-CPS_31:before {
    content: "\e91f";
  }
  .icon-CPS_32:before {
    content: "\e920";
  }
  .icon-CPS_33:before {
    content: "\e921";
  }
  .icon-CPS_34:before {
    content: "\e922";
  }
  .icon-CPS_35:before {
    content: "\e923";
  }
  .icon-CPS_profil_1:before {
    content: "\e924";
  }
  .icon-CPS_profil_2:before {
    content: "\e925";
  }
  .icon-CPS_profil_3:before {
    content: "\e926";
  }
  .icon-CPS_profil_4:before {
    content: "\e927";
  }
  