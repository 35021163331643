.refund-help-layout-divider {
    height: 1.2rem;
    background: url("/assets/images/guest/patterns/CPS_pattern_top_guest.svg");
    background-size: contain;
    background-position: bottom;
    transform: translateY(0.1rem);
}
.patate-3 {
    position: absolute;
    z-index: 10000;
    top: -6rem;
    right: -5.5rem;
    height: 10rem;
    transform: rotate(-20deg);
}
.patate-4 {
    position: absolute;
    z-index: 10000;
    top: -2rem;
    height: 4rem;
    right: 2rem;
    transform: rotate(-18deg);
}
.refund-help-layout {
    position: relative;
    background: linear-gradient(
        to bottom,
        var(--main-color-2) 55%,
        var(--white-color) 45%
    );
    background-color: var(--main-color-2);
    h2,
    p {
        color: var(--white-color);
    }
    .frequent-steps-card-title {
        font-size: 1.3rem;
    }
    .frequent-steps-card-link-text {
        font-weight: 600;
    }
    .frequent-steps-card-link-arrow {
        color: var(--white-color);
    }
    .frequent-steps-card {
        min-height: 20rem;
        max-width: 15rem !important;
        cursor: pointer;
    }
    .frequent-steps-card-overlay {
        background: linear-gradient(
            360deg,
            rgba(0, 32, 83, 0.7) 0%,
            rgba(255, 255, 255, 0) 55%
        );
        height: 100%;
    }
    .frequent-steps-card-link-box {
        height: 2.5rem;
        width: 2.5rem;
        background-color: rgb(25, 239, 188);
    }
    .frequent-steps-card-overlay > h3 {
        color: var(--white-color);
        font-weight: 600;
    }
    .frequent-steps-card-overlay > p {
        font-size: 0.8rem;
        font-weight: 300;
    }
    .frequent-steps-card-overlay > svg {
        height: 2.5rem;
        width: 2.5rem;
    }

    /* card all */
    .frequent-steps-card {
        overflow: hidden;
        background-size: cover;
        p {
            height: 0;
            transform: translateY(20rem);
        }
        a {

            height: 0;
            transform: translateY(20rem);
        }

    }

    /* card 1 */
    .frequent-steps-card-1 {
        background: url("/assets/images/guest/refund-help-1.jpg");
        background-size: cover;

        .frequent-steps-card-link-arrow-box {
            background-color: var(--theme-color-1-2);
        }
        .frequent-steps-card-link-text {
            color: var(--theme-color-1-2);
        }
        .frequent-steps-card-link-box {
            background-color: var(--theme-color-1-2);
        }
    }

    /* card 2 */
    .frequent-steps-card-2 {
        background: url("/assets/images/guest/refund-help-2.jpg");
        background-size: cover;

        .frequent-steps-card-link-arrow-box {
            background-color: var(--theme-color-2-2);
        }
        .frequent-steps-card-link-text {
            color: var(--theme-color-2-2);
        }
        .frequent-steps-card-link-box {
            background-color: var(--theme-color-2-2);
        }
    }

    /* card 3 */
    .frequent-steps-card-3 {
        background: url("/assets/images/guest/refund-help-3.jpg");
        background-size: cover;

        .frequent-steps-card-link-arrow-box {
            background-color: var(--theme-color-3-2);
        }
        .frequent-steps-card-link-text {
            color: var(--theme-color-3-2);
        }
        .frequent-steps-card-link-box {
            background-color: var(--theme-color-3-2);
        }
    }

    /* card 4 */
    .frequent-steps-card-4 {
        background: url("/assets/images/guest/refund-help-4.jpg");
        background-size: cover;

        .frequent-steps-card-link-arrow-box {
            background-color: var(--theme-color-4-2);
        }
        .frequent-steps-card-link-text {
            color: var(--theme-color-4-2);
        }
        .frequent-steps-card-link-box {
            background-color: var(--theme-color-4-2);
        }
    }
}

/* Slider */
.refund-help-slider {
    max-width: 65rem;
}

/* card all */
.refund-help-layout {
    .frequent-steps-card-overlay:hover p {
        transform: translateY(0);
        height: auto;
        display: block;
        transition: ease-out .4s;
        transition-delay: .0s;
    }
    .frequent-steps-card-overlay:hover a {
        transform: translateY(0);
        height: auto;
        display: flex;
        transition: ease-out .4s;
        transition-delay: .2s;
    }
    .frequent-steps-card .icon-CPS_11 {
        color: var(--white-color);
    }
    /* card 1 */
    .frequent-steps-card-overlay:hover {
        background: linear-gradient(
            360deg,
            rgba(0, 97, 129, 1) 0%,
            rgba(0, 97, 129, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-1-2);
        
    }
    /* card 2 */
    .frequent-steps-card-overlay-2:hover {
        background: linear-gradient(
            360deg,
            rgba(135, 75, 0, 1) 0%,
            rgba(135, 75, 0, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-2-2);
    }
    /* card 3 */
    .frequent-steps-card-overlay-3:hover {
        background: linear-gradient(
            360deg,
            rgba(30, 58, 142, 1) 0%,
            rgba(30, 58, 142, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-3-2);
    }
    /* card 4 */
    .frequent-steps-card-overlay-4:hover {
        background: linear-gradient(
            360deg,
            rgba(1, 88, 56, 1) 0%,
            rgba(1, 88, 56, 0.55) 100%
        );
        border-bottom: 4px solid var(--theme-color-4-2);
    }
}

@media (min-width: $breakpoint-md) {
    .refund-help-layout {
        .frequent-steps-card {
            max-width: 20rem !important;
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .patate-3 {
        top: -10rem;
        right: -8.5rem;
        height: 15rem;
        transform: rotate(-10deg);
    }
    .patate-4 {
        top: -6rem;
        height: 6rem;
        right: 2rem;
        transform: rotate(-18deg);
    }
    .carousel-layout {
        .main-slider {
            height: 30rem;
        }
    }
}
@media (min-width: $breakpoint-xl) {
    .patate-3 {
        top: -10rem;
        right: -8.5rem;
        height: 20rem;
        transform: rotate(-10deg);
    }
    .patate-4 {
        top: -6rem;
        height: 8rem;
        right: 6rem;
        transform: rotate(-18deg);
    }
    .refund-help-layout {
        padding-top: 6rem !important;
        .frequent-steps-card {
            max-width: none !important;
        }
    }
}
