:root {
    --theme-color-1-1: #006181;
    --theme-color-1-2: #00b8d0;

    --theme-color-2-1: #894b00;
    --theme-color-2-2: #ff9910;

    --theme-color-3-1: #1e3a8e;
    --theme-color-3-2: #607ed6;

    --theme-color-4-1: #015838;
    --theme-color-4-2: #00b98f;

    --theme-color-5-1: #ffcf4f;
    --theme-color-5-2: #5ae6ff;

    --white-color: #ffffff;
    --dark-color: #000000;
    --light-color: #eff4f8;
    --home-header-opacity: #6d758c46;
    --home-header-opacity: #1e1a4946;
}

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$breakpoint-wide: 1940px;
