.summary-list-layout-divider {
    height: 1.2rem;
    background: url("/assets/images/guest/patterns/CPS_pattern_top_guest.svg");
    background-color: var(--main-color-1);
    background-size: contain;
    background-position: bottom;
}
.summary-list-layout {
    background-color: var(--main-color-1);

    .summary-list-card {
        width: 10rem;
        background-color: gray;
    }
    h2 {
        color: var(--main-color-1);
    }
    h4 {
        color: var(--main-color-2);
        font-size: 0.8rem;
    }
    img {
        height: 6rem;
    }
    li {
        font-size: 0.8rem;
    }
    a {
        text-decoration: none;
        color: var(--white-color) !important;
        font-weight: 300 !important;
        &:hover {
            color: var(--main-color-3) !important;
        }
    }
}
