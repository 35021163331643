.navbar-brand {
    color: var(--white-color) !important;
}
.topbar-layout {
    background: var(--main-color-gradient);
    .dropstart .dropdown-toggle::before {
        display: none;
    }
    .nav-item-topbar-active {
        border-bottom: 2px solid var(--main-color-3) !important;
    }
    .topbar-layout-overlay {
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        background: url("/assets/images/patterns/CPS_pattern_topbar.png");
        background-size: cover;
    }
    .dropdown-toggle {
        color: var(--white-color) !important;
    }
    img {
        height: 2.8rem;
    }

    .collapse {
        z-index: 10;
    }
    .collapse .nav-item-link {
        font-weight: 500;
        color: var(--white-color) !important;
        border-bottom: 2px solid transparent;
        font-size: 0.8rem !important;
        &:hover {
            border-bottom: 2px solid var(--white-color);
        }
    }
    .collapse .e-services-access,
    .nav-magnifier {
        font-weight: 400;
        color: var(--white-color) !important;
    }
    .e-services-access {
        background-color: var(--main-color-3);

        &:hover {
            background-color: var(--main-color-1);
        }
    }
}
