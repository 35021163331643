.subscribe-to-newsletters-layout {
    background-image: url("/assets/images/svg/CPS_oval.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 22rem;
    background-position: center;
    padding-top: 5rem !important;

    h2 {
        color: var(--secondary-color-1);
        font-weight: 600;
    }
    p {
        color: var(--dark-color);
        font-size: 0.9rem;
        font-weight: 300;
    }
    .subscribe-to-newsletters-form {
        height: 100%;
        width: 100%;
        height: 5rem;
        .subscribe-to-newsletters-input {
            height: 3rem;
            max-width: 20rem;
            border: 2px solid var(--main-color-3) !important;
            &::placeholder {
                font-size: 0.8rem;
                padding-left: 1rem;
            }
        }
        & > svg {
            height: 3rem;
        }
        & > svg > path {
            fill: var(--white-color);
        }
        .subscribe-to-newsletters-round-btn {
            background-color: var(--white-color);
            height: 3rem;
            width: 3rem;
            display: flex;
            justify-content: center;
            border: 2px solid var(--main-color-3);
            span {
                font-size: 1.2rem;
            }
            &:hover {
                background-color: var(--main-color-2);
                color: var(--white-color);
            }
        }
    }
}
@media (min-width: $breakpoint-lg) {
    .subscribe-to-newsletters-layout {
        height: 25rem;
        padding-top: 10rem !important;
        background-size: contain;
        background-position: left bottom;
    }
}
@media (min-width: 1900px) {
    .subscribe-to-newsletters-round-btn {
        background-color: var(--main-color-2);
        height: 3rem;
        width: 3rem;
        span {
            font-size: 1.2rem;
        }
        &:hover {
            border: 2px solid var(--white-color);
        }
    }
}
@media (min-width: $breakpoint-wide) {
    .subscribe-to-newsletters-layout {
        margin-top: 10rem !important;
        background-size: cover;
        background-position: top center;
    }
}
